.wrapper {
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
}
.leftBlock {
    font-weight: 400;
    font-size: 12px;
    color : #8c8d8d;
    display: flex;
    flex-direction: column;
    gap : 8px;
    width: 268px;
}
.leftBlock img {
    width: 162px;
    height: 30px;
    opacity: 1;
    transition: 0.3s;
}
.leftBlock img:hover {
    cursor: pointer;
    opacity: 0.5;
}
.rightBlock {
    display: flex;
    gap : 48px;
    font-weight: 400;
    font-size: 16px;
}
.rightBlock a {
    color : #1e1e1e;
    text-decoration: none;
    opacity: 1;
    transition: 0.3s;
}
.rightBlock a:hover {
    cursor: pointer;
    opacity: 0.5;
}

@media (max-width: 1366px) {
    
}

@media (max-width: 1024px) {
    .rightBlock {
        gap : 32px;
        width: 250px;
        flex-wrap: wrap;
        justify-content: left;
    }
}

@media (max-width: 768px) {
    .wrapper {
        flex-direction: column;
        gap: 24px;
    }
    .rightBlock {
        gap : 16px;
        flex-wrap: wrap;
    }
}

