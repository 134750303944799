.inputBlock {
    width: 100%;
    margin-bottom: 16px;
}
.field {
    border: none;
    border-bottom: 2px solid #8c8d8d;
    background-color: transparent;
    width: 100%;
    padding: 8px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    transition: 0.3s;
}
.field:focus {
    outline: none;
    border-bottom: 2px solid #8c8d8d;
}
.field::placeholder {
    color : #1e1e1e;
}
.field.textArea {
    font-family: inherit;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 24px;
}
.field.textArea::-webkit-scrollbar {
    display: none;
}
.field.error {
    border-bottom: 2px solid #ff3d3d;
}
.field.error::placeholder {
    color : #ff3d3d;
}

@media (max-width: 768px) {
    .field {
        font-size: 14px;
    }
}