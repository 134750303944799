.wrapper {
    width: 100%;
    margin-bottom: 80px;
    height: 590px;
    display: flex;
    justify-content: space-between;
}
.item.ceiling {
    margin-bottom: 60px;
}
.item.nonImage {
    justify-content: space-between;
}
.item.floor {
    margin-top: 60px;
}
.item {
    width: calc(25% - 72px);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-color: #ffffff;
    border-radius: 26px;
    padding: 24px;
    position: relative;
    box-shadow: 0px 4px 35px 0px rgba(32, 35, 45, 0.15);
}
.item h3 {
    text-align: center;
    margin: 0;
    font-size: 26px;
    font-weight: 600;
    color : #1e1e1e;
}
.item p {
    font-weight: 400;
    font-size: 18px;
    color : #1e1e1e;
    margin-bottom: 16px;
    text-align: center;
}
.elements {
    display: flex;
    flex-wrap: wrap;
    gap : 4px;
    justify-content: center;
}
.elements p {
    background-color: #228b62;
    padding: 4px 20px;
    margin: 0;
    border-radius: 100px;
    color : #ffffff;
    font-size: 16px;
    font-weight: 500;
}
.logo {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 35px;
    height: 33px;
}
.img {
    width: 100%;
    margin-top: 17px;
}
@media (max-width: 1366px) {
    .wrapper {
        height: 502px;
    }
    .item {
        width: calc(25% - 72px);
        border-radius: 24px;
        padding: 16px;
    }
    .item.nonImage h3{
        margin-bottom: 162px;
    }
    .item h3 {
        font-size: 22px;
    }
    .item p {
        font-size: 16px;
    }
    .elements p {
        padding: 4px 16px;
        border-radius: 90px;
        font-size: 14px;
    }
    .logo {
        width: 32px;
        height: 32px;
    }
}
@media (max-width: 1024px) {
    .wrapper {
        gap : 16px;
        height: 306px;
    }
    .item {
        width: calc(25% - 16px);
        border-radius: 16px;
        padding: 12px;
    }
    .item.nonImage h3{
        margin-bottom: 8px;
    }
    .item h3 {
        font-size: 14px;
    }
    .item p {
        font-size: 12px;
    }
    .elements p {
        padding: 2px 8px;
        border-radius: 52px;
        font-size: 12px;
    }
    .logo {
        width: 18px;
        height: 18px;
    }

}
@media (max-width: 768px) {
    .wrapper {
        height: 592px;
        flex-wrap: wrap;
        gap : 0;
    }
    .item {
        border-radius: 16px;
        padding: 12px;
        height: calc(50% - 40px);
        width: calc(50% - 32px);
    }
    .item.ceiling{
        margin-bottom: 24px;
    }
    .item.floor{
        margin-top: 24px;
    };
    .item h3 {
        font-size: 14px;
    }
    .item p{
        font-size: 12px;
    }
    .img {
        width: 125px;
    }
    .logo {
        width: 18px;
        height: 18px;
        bottom: 12px;
        right: 12px;
    }
    .elements {
        gap : 2px;
    }
    .elements p {
        padding: 2px 8px;
        font-size: 12px;
        border-radius: 50px;
    }
}