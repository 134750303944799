.wrapper {
    margin-bottom: 80px;
    display: flex;
    gap : 24px;
}
.info {
    width: 50%;
}
.info h1 {
    font-weight: 400;
    font-size: 64px;
    color : #1e1e1e;
    margin-bottom: 16px;
}
.info h3 {
    font-weight: 400;
    font-size: 44px;
    color : #8c8d8d;
}
.contact {
    width: 50%;
}
.button{
    border: 1.5px solid #1e1e1e;
    color : #1e1e1e;
    border-radius: 40px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    transition: 0.3s;
}
.button:hover{
    cursor: pointer;
    background-color: #1e1e1e;
    color : #ffffff;
}
.button.error {
    opacity: 0.5;
}
.button.error:hover {
    cursor: auto;
    background-color: transparent;
    color : #1e1e1e;
}
.checkBox {
    display: flex;
    gap : 8px;
    align-items: center;
    padding: 8px 0;
    border-bottom: 2px solid #8c8d8d;
    color : #1e1e1e;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 24px;
}
.checkBox img {
    width: 16px;
    height: 16px;
}
.checkBox img:hover{
    cursor: pointer;
}
.checkBox u {
    opacity: 1;
    transition: 0.3s;
}
.checkBox u:hover {
    cursor: pointer;
    opacity: 0.5;
}
.successEmail {
    display: flex;
    gap : 8px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color  :#1e1e1e;
}
.loader img{
    height: 24px;
    width: 24px;
    animation: rotation 2s infinite linear;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 1366px) {
    .info h1 {
        font-size: 60px;
        margin-bottom: 16px;
    }
    .info h3 {
        font-size: 44px;
    }
    .checkBox {
        font-size: 16px;
    }
}
@media (max-width: 1024px) {
    .wrapper {
        flex-direction: column;
    }
    .info {
        width: 100%;
    }
    .info h1 {
        font-size: 32px;
        margin-bottom: 12px;
    }
    .info h3 {
        font-size: 24px;
    }
    .contact {
        width: 100%;
    }
    .checkBox {
        font-size: 14px;
    }
}

@media (max-width: 768px) {

}