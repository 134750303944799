.wrapper {
    box-sizing: border-box;
    height: 48px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
.wrapper img {
    height: 30px;
    width: auto;
    opacity: 1;
    transition: 0.3s;
}
.wrapper img:hover {
    cursor: pointer;
    opacity: 0.5;
}
.ways {
    display: flex;
    gap : 48px;
}
.ways a {
    text-decoration: none;
    color : #1e1e1e;
    font-weight: 400;
    font-size: 16px;
    opacity: 1;
    transition: 0.3s;
}
.ways a:hover {
    cursor: pointer;
    opacity: 0.5;
}
.button {
    text-decoration: none;
    background-color: #1e1e1e;
    padding: 12px 24px;
    color : #fbfcfd;
    font-size: 16px;
    font-weight: 500;
    border-radius: 40px;
    transition: 0.3s;
    border : 2px solid #1e1e1e;
}
.button:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #1e1e1e;
}
@media (max-width: 1024px) {
    .mobileWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 27px;
    }
    .logoImg {
        width: 117px;
        height: 22px;
        transition: 0.3s;
        opacity: 1;
    }
    .logoImg:hover {
        cursor: pointer;
        opacity: 0.5;
    }
    .menuImg {
        z-index: 100000;
        width: 24px;
        height: 24px;
        opacity: 1;
        transition: 0.5s;
    }
    .menuImg:hover {
        cursor: pointer;
        opacity: 0.5;
    }
    .background {
        left: 0;
        top : 0;
        position: fixed;
        z-index: 10000;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.30);
    }
    .menu {
        position: absolute;
        top : 0;
        right: 0;
        z-index: 100;
        width: 50vw;
        height: 100vh;
        background-color: #fbfcfd;
    }
    .body {
        margin-top: 144px;
        margin-bottom: 100px;
    }
    .block a {
        text-decoration: none;
        color : #1e1e1e;
        font-size: 16px;
        font-weight: 400;
        opacity: 1;
        transition: 0.3s;
    }
    .block a:hover {
        opacity: 0.5;
        cursor: pointer;
    }
    .block {
        display: flex;
        gap : 32px;
        flex-direction: column;
    }
}
@media (max-width: 768px) {
    .menu {
        width: 100vw;
    }
    .logoImg {
        z-index: 100000;
    }
}