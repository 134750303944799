.wrapper {
    background-image: url("footer_background.png");
    max-width: 100vw;
    box-sizing: border-box;
    padding: 40px 17.5% 12px;
    color : #ffffff;
}
.content h1 {
    font-weight: 500;
    font-size: 44px;
    margin-bottom: 8px;
}
.infoBlocks {
    display: flex;
    flex-direction: column;
    gap : 24px;
    margin-bottom: 32px;
}
.block {
    display: flex;
    gap : 24px;
}
.item {
    width: 30%;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
}
.title {
    font-weight: 500;
}
.footer {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
}
.info {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap : 8px;
}
.info p {
    display: flex;
    gap : 8px;
    align-items: center;
}
@media (max-width: 1366px) {
    .wrapper {
        padding: 12px 88px;
    }
}

@media (max-width: 1024px) {
    .wrapper {
        padding: 12px 164px;
    };
    .content h1 {
        font-size: 32px;
    }
    .item {
        width: 100%;
        font-size: 14px;
        line-height: 18px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 30px 16px;
    }
    .infoBlocks {
        gap : 16px;
    }
    .block {
        gap : 16px;
        flex-direction: column;
    }
}