.wrapper {
    max-width: 100vw;
    box-sizing: border-box;
    padding: 12px 17.5%;
    background-image: url("background.png");
}
@media (max-width: 1366px) {
    .wrapper {
        padding: 12px 88px;
    };

}
@media (max-width: 1024px) {
    .wrapper {
        padding: 12px 164px;
    };

}
@media (max-width: 768px) {
    .wrapper {
        padding: 12px 16px;
    };

}