.wrapper {
    margin-bottom: 80px;
}
.background {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 40px;
    height: fit-content;
}
.big {
    background-image: url("rectangle_background.png");
    background-size: 100% 100%;
    width: 67%;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 63px 0 40px 0;
}
.info {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.info img {
    width: 100%;
    height: auto;
}
.info h1 {
    font-weight: 400;
    font-size: 48px;
    color : #1e1e1e;
    text-align: left;
    margin-left: 44px;
    margin-bottom: 16px;
}
.info p {
    font-weight: 400;
    font-size: 22px;
    color : #1e1e1e;
    text-align: left;
    width: 80%;
    margin-left: 44px;
}
.little {
    width: 15%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("rectangle_background_little.png");
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 13px;
}
.circle {
    text-decoration: none;
    background-color: #228b62;
    font-weight: 500;
    color : #ffffff;
    font-size: 18px;
    text-align: center;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    width: 100%;
    aspect-ratio : 1 / 1;
    gap : 0 !important;
    line-height: 24px;
}
.circle:hover {
    background-color: #1e1e1e;
    cursor: pointer;
}
.circle img {
    width: 32px;
    height: 32px;
}
.circle.outside {
    display: none;
}
.block {
    display: flex;
    gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
}
.item {
    padding: 12px 34px;
    border-radius: 100px;
    border : 1.5px solid #8c8d8d;
    font-size: 18px;
    font-weight: 500;
    color : #8c8d8d;
    white-space: nowrap
}

@media (max-width: 1366px) {
    .background {
        margin-bottom: 24px;
    }
    .big {
        padding: 50px 0;
    }
    .info {
        justify-content: center;
    }
    .info h1 {
        margin-left: 32px;
    }
    .info p {
        margin-left: 32px;
    }
    .little {
        padding: 12px;
    }
    .circle {
        font-size: 16px;
    }
    .block {
        gap : 20px;
    }
    .item {
        padding: 12px 30px;
    }
}
@media (max-width: 1024px) {
    .wrapper {
        margin-bottom: 60px;
    }
    .big {
        width: 83%;
    }
    .little {
        width: 15%;
    }
    .little.right {
        display: none;
    }
    .info h1 {
        font-size: 22px;
        margin-left: 24px;
        margin-bottom: 8px;
    }
    .info p {
        font-size: 16px;
        margin-left: 24px;
    }
    .little {
        padding: 7px;
    }
    .circle {
        font-size: 12px;
        line-height: 14px;
    }
    .circle img {
        width: 18px;
        height: 18px;
    }
    .block {
        gap: 6px;
    }
    .item {
        padding: 8px 16px;
        border-radius: 100px;
        font-size: 14px;
    }
}
@media (max-width: 768px) {
    .wrapper {
        margin-bottom: 60px;
    }
    .background {
        margin-bottom: 16px;
    }
    .big {
        width: 100%;
        padding: 32px 0 32px 0;
    }
    .info {
        justify-content: center;
    }
    .info h1 {
        margin-left: 16px;
    }
    .info p {
        margin-left: 16px;
    }
    .little {
        display: none;
        position: absolute;
    }
    .block {
        gap : 4px;
        align-items: flex-start;
        align-content: flex-start;
    }
    .item {
        font-size: 14px;
        padding: 8px 16px;
    }
    .circle {
        font-size: 12px;
        width: 80px;
        line-height: 18px;
        margin: auto;
        margin-top: 16px;
    }
    .circle img {
        width: 16px;
        height: 16px;
    }
    .circle.block {
        display: none;
    }
    .circle.outside {
        display: flex;
    }
}