.wrapper {
    margin-bottom: 80px;
}
.header {
    margin-bottom: 28px;
    display: flex;
    gap : 24px;
}
.header h1 {
    font-weight: 400;
    font-size: 64px;
    color : #1e1e1e;
    width: 50%;
    text-align: left;
    margin: 0;
}
.header p {
    font-weight: 400;
    font-size: 18px;
    color : #1e1e1e;
    text-align: left;
    width: 50%;
}
.content {
    display: flex;
    gap : 24px;
}
.leftBlocks, .rightBlocks {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap : 24px;
}
.block {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0px 4px 35px 0px rgba(32, 35, 45, 0.15);
}
.block.big {
    height: 75%
}
.block.little {
    height: auto;
    display: flex;
    flex-direction: row;
    gap : 24px;
}
.block.big img{
    margin-bottom: 24px;
}
.block img {
    width: 100%;
    height: auto;
}

.items {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}
.items.big {
    justify-content: right;
    margin-bottom: 24px;
}

.items span {
    padding: 4px 20px;
    border : 2px solid #8c8d8d;
    border-radius: 100px;
    color : #8c8d8d;
    font-weight: 500;
    font-size: 16px;
}
.title {
    font-weight: 500;
    font-size: 32px;
    color : #1e1e1e;
    margin-bottom: 8px;
}
.text {
    font-weight: 400;
    font-size: 16px;
    color : #1e1e1e;
    line-height: 24px;
}
.littleBlockInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.block.little img{
    object-fit: contain;
}
@media (max-width: 1366px) {
    .header {
        margin-bottom: 40px;
    }
    .header h1 {
        font-size: 60px;
    }
    .header p {
        font-size: 18px;
    }
    .title {
        font-size: 28px;
    }
    .block.big img{
        margin-bottom: 16px;
    }
    .block {
        padding: 16px;
    }
    .items.big {
        margin-bottom: 16px;
    }
    .block.big {
        height: fit-content;
    }
    .block.little {
        gap : 16px;
    }

}
@media (max-width: 1024px) {
    .wrapper {
        margin-bottom: 60px;
    }
    .header {
        flex-direction: column;
        gap : 12px;
        margin-bottom: 24px;
        width: 100%;
    }
    .header h1 {
        font-size: 32px;
        width: 100%;
    }
    .header p {
        width: 100%;
        font-size: 14px;
    }
    .items {
        gap: 4px;
    }
    .items.big {
        justify-content: right;
        margin-bottom: 12px;
    }
    .items span {
        padding: 2px 8px;
        border-radius: 60px;
        font-size: 12px;
    }
    .title {
        font-size: 18px;
        margin-bottom: 4px;
    }
    .text {
        font-size: 12px;
        line-height: 18px;
    }
    .block.little img{
        width: calc(50% - 12px);
    }
    .block.big img{
        margin-bottom: 12px;
    }

}
@media (max-width: 768px) {
    .header {
        gap : 12px;
        margin-bottom: 24px;
    }
    .header h1 {
        font-size: 32px;
    }
    .header p {
        font-size: 14px;
        width: 100%;
    }
    .content {
        flex-direction: column;
        gap : 8px;
    }
    .leftBlocks, .rightBlocks {
        gap : 8px;
        width: 100%;
    };
    .block {
        padding: 12px;
        border-radius: 16px;
    }
    .block {
        padding: 12px;
        border-radius: 16px;
    }
    .block.big {
        height: fit-content;
    }
    .block.little {
        gap : 12px;
        min-height: fit-content;
        height: fit-content;
    }
    .items {
        gap : 4px;
    }
    .items span {
        padding: 2px 8px;
        border-radius: 60px;
        font-size: 12px;
    }
    .items.big {
        margin-bottom: 12px;
    }
    .items.little {
        margin-top: 12px;
    }

}