.wrapper {
    margin-bottom: 135px;
}
.header {
    display: flex;
    gap : 24px;
    flex-direction: row;
    margin-bottom: 56px;
}
.header h1 {
    text-align: left;
    font-size: 64px;
    margin: 0;
    font-weight: 400;
    color : #1e1e1e;
}
.info {
    display: flex;
    flex-direction: column;
    gap : 16px;
    justify-content: left;
}
.info h4 {
    color : #8c8d8d;
    font-weight: 400;
    font-size: 44px;
    margin: 0;
    text-align: left;
}
.info p {
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    color : #1e1e1e;
}
.info a {
    text-decoration: none;
    color : #1e1e1e;
    background-color: transparent;
    border : 2px solid #1e1e1e;
    padding: 8px 8px 8px 16px;
    border-radius: 40px;
    display: flex;
    width: fit-content;
    align-items: center;
    gap : 10px;
    font-weight: 500;
    font-size: 16px;
    transition: 0.3s;
}
.info a:hover {
    cursor: pointer;
    background-color: #1e1e1e;
    color : #ffffff;
}
.info a img {
    width: 32px;
    height: 32px;
}
.content {
    display: flex;
    flex-direction: column;
    gap : 24px;
    width: 100%;
}
.topBlocks, .bottomBlocks {
    display: flex;
    gap: 24px;
    box-sizing: border-box;
    width: 100%;
}
.bottomBlocks div {
    width: 29%;
}
.block {
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 24px;
    box-shadow: 0px 4px 35px 0px rgba(32, 35, 45, 0.15);
}
.block img{
    height: 80px;
    width: auto;
    margin-bottom: 16px;
    max-width: 100%;
    display: block;
    margin-right: auto;
}
.block h4{
    text-align: left;
    font-weight: 500;
    font-size: 32px;
    color : #1e1e1e;
    margin-bottom: 8px;
}
.block p{
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    color : #1e1e1e;
}

.littleBlock {
    min-width: calc(30% - 24px);
}

@media (max-width: 1366px) {
    .wrapper {
        margin-bottom: 80px;
    }
    .header {
        gap : 24px;
        margin-bottom: 56px;
    }
    .header h1 {
        font-size: 60px;
    }
    .block h4{
        font-size: 28px;
    }
}

@media (max-width: 1024px) {
    .wrapper {
        margin-bottom: 32px;
    }
    .header {
        flex-direction: column;
        gap : 12px;
        margin-bottom: 0;
    }
    .header h1{
        font-size: 32px;
    }
    .info {
        gap : 8px;
    }
    .info h4 {
        font-size: 24px;
    }
    .info p {
        font-size: 14px;
    }
    .info a {
        padding: 6px 6px 6px 12px;
        font-size: 14px;
        gap : 8px;
    }
    .info a img {
        width: 18px;
        height: 18px;
    }
    .bottomBlocks div {
        width : auto;
    }
    .block {
        padding: 12px;
        min-height: 312px;
        min-width: 336px;
    }
    .block h4{
        font-size: 18px;
        margin-bottom: 4px;
    }
    .block p{
        font-size: 14px;
    }
    .blockWrapper {
        text-decoration: none;
        position: relative;
    }
    .blocks{
        padding-top: 30px;
        padding-bottom: 50px;
        display: flex;
        gap: 24px;
        overflow-x : scroll;
        width: 100%;
    }
    .blocks::-webkit-scrollbar  {
        width: 0;
    }
    .arrowsBlock {
        display: flex;
        justify-content: center;
        gap : 24px;
    }
    .arrow {
        padding: 8px 16px;
        background-color: #b1bacc;
        border-radius: 58px;
        transition: 0.5s;
    }
    .arrow:hover {
        cursor: pointer;
        background-color: #228b62;
    }
    .block img {
        height: 60px;
        width: auto;
        margin-bottom: 12px ;
    }
}

@media (max-width: 768px) {

}