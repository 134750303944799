.wrapper {
    color : #1e1e1e;
    margin-bottom: 80px;
}
.wrapper h1{
    font-size: 64px;
    font-weight: 400;
    margin-bottom: 16px;
}
.content {
    font-size: 18px;
    line-height: 24px;
}

@media (max-width: 1366px) {
    .wrapper h1 {
        font-size: 60px;
        line-height: 56px;
        margin-bottom: 16px;
    }
}

@media (max-width: 1024px) {
    .wrapper {
        margin-bottom: 60px;
    }
    .wrapper h1 {
        font-size: 32px;
        line-height: 30px;
        margin-bottom: 16px;
    }
    .content {
        font-size: 14px;
        line-height: 18px;
    }
}
